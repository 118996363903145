import { adminAuthGuard } from './core/guards/admin-auth.guard'
import { AuthGuard } from './core/guards/auth.guard'

import { Routes } from '@angular/router'

export const routes: Routes = [
  { path: 'ui-kit', loadComponent: async () => (await import('./ui-kit')).UiKitComponent, data: { animation: 'ui-kit' } },
  { path: 'login', loadComponent: async () => (await import('./features/login')).LoginComponent, data: { animation: 'login' } },
  {
    path: 'profile',
    loadComponent: async () => (await import('./features/user/components/user-profile')).UserProfileComponent,
    data: { animation: 'user-profile' },
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/profile',
    loadComponent: async () => (await import('./features/admin/components/admin-profile')).AdminProfileComponent,
    data: { animation: 'admin-profile' },
    canActivate: [adminAuthGuard],
  },
  {
    path: 'user',
    loadComponent: async () => (await import('./features/user')).UserComponent,
    data: { animation: 'Main' },
    loadChildren: () => import('./features/user/user.routes').then((r) => r.USER_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadComponent: async () => (await import('./features/admin')).AdminComponent,
    data: { animation: 'Admin' },
    loadChildren: () => import('./features/admin/admin.routes').then((r) => r.ADMIN_ROUTES),
    canActivate: [adminAuthGuard],
  },
  { path: '', loadComponent: async () => (await import('./features/main')).MainComponent, data: { animation: 'main' } },
  { path: 'devices/:id', loadComponent: async () => (await import('./features/devices')).DevicesComponent, data: { animation: 'devices' } },
  { path: 'news', loadComponent: async () => (await import('./features/news')).NewsComponent, data: { animation: 'news' } },
  { path: 'partners', loadComponent: async () => (await import('./features/partners')).PartnersComponent, data: { animation: 'partners' } },
]
