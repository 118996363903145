<div class="bg-background-black text-background-white">
  <div class="wrapper">
    <ng-container *ngIf="isLargeFooter$ | async">
      <div class="l-footer hidden md:flex w-full justify-between px-16 pt-60 pb-60 gap-40">
        <div *ngFor="let col of footerItems$ | async" class="flex-1">
          <div class="text-16 font-500">{{ col.label }}</div>
          <div class="flex flex-col mt-24 gap-16">
            <a *ngFor="let link of col.items" [routerLink]="link.url" class="text-13 font-500 opacity-50">{{ link.label }}</a>
          </div>
        </div>
      </div>
      <div class="py-40 hidden md:flex w-full justify-between">
        <div class="flex flex-col gap-16">
          <a *ngFor="let link of singleItems | async" [routerLink]="link.url" class="text-16 font-500">{{ link.label }}</a>
        </div>
        <div class="flex flex-col gap-10">
          <span class="text-16">Слідкуй за нами тут:</span>
          <div class="social-wrapper flex gap-12">
            <a *ngFor="let link of socialIcons" [routerLink]="link.url">
              <mat-icon [svgIcon]="link.icon" />
            </a>
          </div>
        </div>
      </div>
    </ng-container>

    <footer class="footer flex items-center w-full gap-10 text-background-white md:px-32">
      <div class="footer__logs">
        <div class="flex items-center gap-10">
          <mat-icon [svgIcon]="svgIcons.EMBLEM" />
          <mat-icon [svgIcon]="svgIcons.LOGO_WHITE" />
        </div>
      </div>
      <div class="footer__privacy text-15 font-300">
        ДП ДІЯ 2019 - 2024. <br />
        Всі права захищені.
      </div>
      <div class="footer__links md:ml-auto text-16 font-400 flex flex-col gap-8">
        <a href="https://maps.app.goo.gl/RjuHEzGpDysSaEtd9" target="_blank"> Адреса: м. Київ, вул. Генерала Алмазова, 11 </a>
        <a href="mailto:keys@diia.gov.ua">keys&#64;diia.gov.ua</a>
      </div>
    </footer>
  </div>
</div>
